import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Table, Typography } from 'antd';
import { useIntl } from 'react-intl';
import messages from './messages';
import {
  RETURN_REASON_LATEDELIVERY,
  RETURN_REASON_DONTLIKEANYMORE,
  RETURN_REASON_MISSINGPARTSORACCESSORIESMISSING,
  RETURN_REASON_ITEMBROKENORDAMAGED,
  RETURN_REASON_MISPICKWRONGITEMDELIVERED,
  RETURN_REASON_FOUNDCHEAPERPRICE,
  RETURN_REASON_SHIPPINGPACKAGINGDAMAGED,
  RETURN_REASON_WRONGDESCRIPTION,
  RETURN_REASON_ITEMTOOBIGORSMALL,
  RETURN_REASON_NONE,
  RETURN_STATUS_CREATED,
  RETURN_STATUS_CREATING,
  RETURN_STATUS_NOTRETURNED,
  RETURN_STATUS_PROCESSED,
} from './constants';

export const getReturnReasonsOptions = (intl) => [
  {
    label: intl.formatMessage(messages.returnReason_None),
    value: RETURN_REASON_NONE,
  },
  {
    label: intl.formatMessage(messages.returnReason_LateDelivery),
    value: RETURN_REASON_LATEDELIVERY,
  },
  {
    label: intl.formatMessage(messages.returnReason_DontLikeAnymore),
    value: RETURN_REASON_DONTLIKEANYMORE,
  },
  {
    label: intl.formatMessage(messages.returnReason_MissingPartsOrAccessories),
    value: RETURN_REASON_MISSINGPARTSORACCESSORIESMISSING,
  },
  {
    label: intl.formatMessage(messages.returnReason_ItemBrokenOrDamaged),
    value: RETURN_REASON_ITEMBROKENORDAMAGED,
  },
  {
    label: intl.formatMessage(messages.returnReason_WrongItemDelivered),
    value: RETURN_REASON_MISPICKWRONGITEMDELIVERED,
  },
  {
    label: intl.formatMessage(messages.returnReason_FoundCheaperPrice),
    value: RETURN_REASON_FOUNDCHEAPERPRICE,
  },
  {
    label: intl.formatMessage(messages.returnReason_ShippingPackageDamaged),
    value: RETURN_REASON_SHIPPINGPACKAGINGDAMAGED,
  },
  {
    label: intl.formatMessage(messages.returnReason_WrongDescription),
    value: RETURN_REASON_WRONGDESCRIPTION,
  },
  {
    label: intl.formatMessage(messages.returnReason_ItemTooBigOrSmall),
    value: RETURN_REASON_ITEMTOOBIGORSMALL,
  },
];

const getReturnStatusOptions = (intl) => [
  {
    label: intl.formatMessage(messages.created),
    value: RETURN_STATUS_CREATED,
  },
  {
    label: intl.formatMessage(messages.processed),
    value: RETURN_STATUS_PROCESSED,
  },
  {
    label: intl.formatMessage(messages.notReturned),
    value: RETURN_STATUS_NOTRETURNED,
  },
  {
    label: intl.formatMessage(messages.creating),
    value: RETURN_STATUS_CREATING,
  },
];

const getColumns = (intl, reasonOptions) => [
  {
    title: intl.formatMessage(messages.positionNumber),
    dataIndex: 'positionNumber',
    width: '8%',
  },
  {
    title: intl.formatMessage(messages.itemNumber),
    dataIndex: 'itemNumber',
    width: '8%',
  },
  {
    title: intl.formatMessage(messages.itemName),
    dataIndex: 'description',
  },
  {
    title: intl.formatMessage(messages.askedQuantity),
    dataIndex: 'askedQuantity',
    width: '8%',
    render: (text) => (text === 0 ? '' : text),
  },
  {
    title: intl.formatMessage(messages.returnedQuantity),
    dataIndex: 'returnedQuantity',
    render: (text, record) => record.isProcessed && text,
    width: '8%',
  },
  {
    title: intl.formatMessage(messages.returnReason),
    dataIndex: 'returnReason',
    width: '20%',
    render: (text) => reasonOptions.find(({ value }) => value === text)?.label,
  },
];

const ReturnsTable = ({ data = [], loading }) => {
  const intl = useIntl();
  const reasonOptions = getReturnReasonsOptions(intl);
  const statusOptions = getReturnStatusOptions(intl);
  const columns = useMemo(
    () => getColumns(intl, reasonOptions),
    [intl, reasonOptions],
  );

  return data.map((returnEntry) => {
    return (
      <div style={{ padding: 10 }} key={returnEntry.internalReturnNumber}>
        <Row style={{ marginBottom: 8, marginTop: 24 }}>
          <Col span={12} align="left">
            <Typography.Text
              style={{
                marginLeft: 8,
                fontSize: 16,
                fontWeight: 'bold',
              }}
            >
              {intl.formatMessage(messages.returnRequest)}:{' '}
              {returnEntry.internalReturnNumber}
              {' ('}
              {intl.formatDate(returnEntry.returnDate)}
              {')'}
            </Typography.Text>
          </Col>
          <Col span={12} align="right">
            <Typography.Text
              style={{
                marginRight: 8,
                fontSize: 16,
                fontWeight: 'bold',
              }}
            >
              {intl.formatMessage(messages.status)}:{' '}
              {
                statusOptions.find(({ value }) => value === returnEntry.status)
                  ?.label
              }
            </Typography.Text>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={returnEntry.returnPositions}
          pagination={false}
          loading={loading}
          bordered
          size="small"
          rowKey="positionNumber"
        />
        <div
            style={{
              marginTop: 8,
              textAlign: 'right',
            }}
          >
          {returnEntry.hasReturnLabel && (
            <Button
              type="link"
              icon={<DownloadOutlined />}
              href={`/api/home-delivery/returns/download-labels-by-customer/${returnEntry.id}`}
              style={{
                backgroundColor: '#1677ff',
                color: '#fff',
                border: '1px solid #1677ff',
                borderRadius: 4,
                padding: '0 15px',
              }}
            >
              {intl.formatMessage(messages.returnFormDownload)}
            </Button>
          )}
        </div>
        <Divider />
      </div>
    );
  });
};

ReturnsTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default ReturnsTable;
